<template>
  <div v-if="!isSearch" class="allPage">
    <heads :show_search="showSearch" :msg="title_msg" @doSearch="doSearch"></heads>
    <van-list
      v-show="isShow"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(thread, index) in list" :key="index" @click="go_url(thread.detail_url)">
        <datalists
          :type="1"
          :listType="2"
          :thumb="thread.pic"
          :atitle="thread.title"
          :createTime="thread.create_time"
          :vNum="thread.view_num"
          :cNum="thread.reply_num"
        ></datalists>
      </div>
    </van-list>
    <div v-show="!isShow" class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt />
      <p>{{tip}}</p>
    </div>
  </div>
  <div v-else>
    <div class="div_search">
      <van-search
         style="width: 100%"
         v-model="search_value"
         shape="round"
         @keyup.enter.native="searchPost"
         placeholder="搜索我的发帖"
      />
      <span @click="searchPost">搜索</span>
    </div>
    <div class="div_search_content">
      <div class="div_title">
        <span>历史搜索</span>
      </div>
      <div class="div_list">
        <span class="div_list_item" v-for="item in searchList"  :slot="item" @click="onLoad(item.word,1)">{{item.word}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import heads from "@/compontens/heads.vue";
import { my_fatie, getSearchHistory, addSearchHistory } from "@/api/my_user.js";
import commontop from '@/compontens/menu.vue'
import tool from '@/utils/tools.js'
import datalists from '@/compontens/datalist'
import Cookies from 'js-cookie'

export default {
  components: {
    heads,
    commontop,
    datalists
  },
  data () {
    return {
      tip:'您还没有发布帖子哦~',
      search_value:'',
      searchList:[],
      title_msg: "我的发帖",
      list: [],
      times: '',
      isSearch: false,
      isshow: false,
      limit: 10,
      page: 1,
      total: 0,
      retlen: 0,
      loading: false,
      finished: false,
      isShow: true,
      showSearch: true,
      uid: Cookies.get('uid') ? Cookies.get('uid') : ''
    };
  },
  destroyed () {
    this.list = []
    this.page = 1
    this.total = 0
  },
  beforeMount () {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function (e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  mounted () {
    // this.get_list();
    this.getSearchHistory()
  },
  methods: {
    getSearchHistory(){
      getSearchHistory().then(res =>{
        if (res.errNo === 0){
          this.searchList = res.result
        }
      })
    },
    searchPost(){
      if (this.search_value){
        addSearchHistory({word:this.search_value}).then(res =>{
        })
        this.isSearch = false
      }else {
        this.isSearch = false
      }
      this.onLoad(this.search_value,1)
    },
    doSearch(){
      this.getSearchHistory()
      this.search_value = ''
      this.isShow = true
      this.finished = false
      this.isSearch = true
    },
    go_url (urls) {
      window.location.href = urls
    },
    clicks () {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    onLoad (val,type) {
      this.isSearch = false
      this.loading = true
      console.log(this.uid);
      var params = {
        page: this.page,
        q:val,
        limit: this.limit,
        uid: this.uid
      }
      if (type == 1){
        this.list = []
        this.page = 1
        this.tip = '您还没有发布关于【'+val+'】的帖子哦~'
      }else {
        this.tip = '您还没有发布帖子哦~'
      }
      my_fatie(params).then((res) => {
        const result = res.result
        const count = result.count
        const list = result.list ? result.list : []
        // 加载完成
        this.loading = false
        // 有数据，展示列表
        this.isShow = true
        this.list = this.list.concat(res.result.list)
        // 数据总条数
        this.total = count
        // 本次返回数据的长度
        this.retlen = list.length
        if (this.retlen < this.limit) {
          // 数据加载完毕显示 没有更多了
          this.finished = true
        }
        else {
          // 尚有数据
          this.page++
          this.finished = false
        }
        if (!list.length && !this.list.length) {
          this.isShow = false
          return false
        }
      });
    },
    formatDate (date) {
      var date = new Date(date * 1000);
      return tool.formatDate(date, 'yyyy-MM-dd')
    }
  },
};
</script>


<style lang="scss" scoped>
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
.div_search{
  display: flex;
  align-items: center;
  span{
    flex-shrink: 0;
    width: 80px;
    text-align: left;
    font-size: 30px;
  }
}
.div_search_content{
  margin: 24px 24px;
  .div_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
    span{
      font-size: 32px;
      color: #333333;
    }
  }
  .div_list{
    display: flex;
    flex-wrap: wrap;
    height: 290px ;
    overflow-y: hidden;
    .div_list_item{
      flex-shrink: 0;
      max-width: 180px;
      height: 50px;
      text-align: center;
      line-height: 54px;
      align-items: center;
      padding: 12px 26px;
      border-radius: 16px;
      margin-right: 24px;
      margin-bottom: 24px;
      background: #F6F6F6;
      white-space: nowrap;
      overflow: hidden;//文本超出隐藏
      text-overflow: ellipsis;//文本超出省略号替代
    }
  }
}
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
